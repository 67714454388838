import axios from 'axios'

const _axios = axios.create({
    timeout: 60 * 1000,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
})

_axios.interceptors.request.use(
    async config => {
        config.headers.token = 'omnfz5OBS4UEwBwm4BbHdsPvnDeU'
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

_axios.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
)

export default _axios
