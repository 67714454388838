import {Icon} from 'antd-mobile'
import React, {Component, lazy, Suspense} from 'react'
import {Route, Switch} from 'react-router-dom'

const Navigator = () => (
    <Suspense fallback={<PageLoading />}>
        <Switch>
            <Route exact path="/entry" component={lazy(() => import('./Language'))} />
            <Route exact path="/" component={lazy(() => import('./Entry'))} />
            <Route exact path="/detail/:id" component={lazy(() => import('./Detail'))} />
            <Route exact path="/poster" component={lazy(() => import('./Poster'))} />
        </Switch>
    </Suspense>
)

export default Navigator

const PageLoading = () => (
    <div
        style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <Icon type="loading" />
    </div>
)
